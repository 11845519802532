import { Flex, Heading } from "@chakra-ui/react";
import { Button } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import useAppLogo from "./assets/use-app-icon.png";

function AppPage() {
  return (
    <Flex gap="112px" direction="column" align="center">
      <Flex gap="32px" direction="column" align="center">
        <div>
          <img src={useAppLogo} className="shared-logo" alt="Use logo" />
        </div>
        <Heading as="h1">use Group</Heading>
        <Button asChild>
          <Link to="/stations/heinemuende/rent">Go to Heinemuende Station</Link>
        </Button>
      </Flex>
    </Flex>
  );
}

export default AppPage;
